import { PageView } from "../../components"
import { UserContext } from "../../hooks/UserContext"
import { fetchHomeSummary } from "../../services/storeServices"
import { useQuery } from "react-query"
import { useContext } from "react"
import { MetricsContainer } from "./MetricsContainer"
import { OrderStatsContainer } from "./OrderStatsContainer"
import SelfOnboarding from "./SelfOnboarding"
import { useTranslation } from "react-i18next"
import { Title } from "@tremor/react"


export const Home = () => {
    const { user } = useContext(UserContext)
    const { t } = useTranslation()
    const pdf = require('../../pages/Home/CalendarioNavidad2024.pdf')
    const {
        isLoading: isLoading,
        // isError: isError,
        // error,
        data: onboardingData,
        // isFetching,
        // isPreviousData,
        refetch,
    } = useQuery(["home_info", user.current_store?.id], () => fetchHomeSummary(user.current_store?.id), {
        staleTime: 1000 * 60 * 1, // after 1min, refetch if screen is focused
    })

    const onReloadData = () => {
        refetch()
    }

    return (
        <PageView>
            <section>
                <div className="mt-1 mb-5">
                    <Title className="font-semibold text-black !text-xl">{t("home.title", { store_name: user.current_store?.name })}</Title>
                </div>
            </section>

            {onboardingData && onboardingData["onboarding_data"] && onboardingData["onboarding_data"]["requires_onboarding"] && (
                <section>
                    <SelfOnboarding
                        onboardingData={onboardingData && onboardingData["onboarding_data"]}
                        showLoader={isLoading}
                        onReloadData={() => onReloadData()}
                    />
                </section>
            )}
            {user.current_store?.warehouses[0]?.country === "MX" && (
                <section>
                    <div className="rounded-lg bg-yellow-200 text-yellow-700 p-7 mb-5 mt-5">
                        {/* <div className="font-bold">🚨 PREPÁRATE PARA EL BLACK FRIDAY 2024 🚨</div>
                        <div className="mt-1">Los siguientes servicios serán suspendidos del 28 de noviembre al 2 de diciembre:
                            <ul className="list-disc ml-5">
                                <li>Recibos de inventario.</li>
                                <li>Logística inversa.</li>
                                <li>Pedidos REM.</li>
                                <li>Maquilas.</li>
                            </ul>
                        </div>
                        <br></br> */}
                        <div className="mt-1">
                            Tus clientes esperan sus pedidos con ansias en Diciembre, y en Cubbo vamos a ayudarte a cumplir con sus expectativas. Por eso <a href={pdf} target="_blank" >aquí</a> encontrarás las ultimas fechas recomendadas para asegurar las entregas a tiempo para Navidad y Reyes 🎄 🎁
                        </div>

                        {/* <div className="mt-1">
                            Sobre tiempos de procesamiento y entrega:
                            <ul className="list-disc ml-5">
                                <li>Procesaremos todos los pedidos en un máximo de 48 hrs.</li>
                                <li>Los tiempos de entrega se extenderán hasta 48 hrs adicionales.</li>
                            </ul>
                        </div> */}
                    </div>
                </section>
            )}

            {user.current_store?.warehouses[0]?.country === "BR" && (
                 <section>
                    <div className="rounded-lg bg-yellow-200 text-yellow-700 p-7 mb-10 mt-5 flex flex-col">
                        <div> 📢 ATENÇÃO: PROGRAMAÇÃO OPERACIONAL DE FINAL DE ANO 📢 </div>
                        <br></br>
                        <div>📅 Dezembro:</div>
                        <div>
                            <li>23 e 30/12: Operação reduzida (50%) | 08h às 18h</li>
                            <li>24 e 31/12: Horário e operação reduzida (50%) | 07h às 15h</li>
                            <li>25/12: Sem operação</li>
                            <li>Demais dias do mês: Operação normal</li>
                        </div>
                           <br></br>
                        <div>📅 Janeiro:</div>
                        <div>
                            <li>01/01/2024: Sem operação</li>
                        </div>
                        <br></br>
                        <div>
                            🔔 Planeje-se com antecedência! 🚛
                        </div>
                   </div> 
                </section>
            )}

            <section>
                <OrderStatsContainer storeId={user.current_store?.id} />
            </section>

            <section>
                <MetricsContainer storeId={user.current_store?.id} />
            </section>
        </PageView>
    )
}
