import {useState, useContext, useRef, useMemo, useEffect, componentDidMount} from 'react';
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {useQuery} from "react-query";
import {enqueueOrdersExport, fetchOrders, getOrdersExport, fetchStoreCubboShippingMethodsGroup, fetchChannels} from "../../services";
import {useDispatch, useSelector} from "react-redux";
import { setOrdersListPageSize } from '../../redux/pageSizeSlice';
import {
    ActionMenu,
    NewTable, ButtonLoader, ConfirmDialog,
    FormattedDate, Notification,
    PageTopBar,
    PageView,
    SlidePanel,
} from "../../components"
import {SHOW_ORDERS} from "../../navigation"
import {ADD_ORDER} from "../../navigation"
import {AddOrderContainer} from "./AddOrder/AddOrderContainer"
import {DuplicateOrderContainer} from "./AddOrder/DuplicateOrderContainer"
import {
    CLAIMS,
    CREATE_CLAIM,
    CREATE_RETURN,
    ADD_ORDER_REMOVE_STOCK,
    REMOVE_STOCK_CREATED,
    EDIT_ORDER,
    EDIT_ORDER_REMOVE_STOCK,
    DUPLICATE_ORDER,
    ORDERS,
    RETURNS,
    SHOW_RETURN,
    STORE_PATH,
    CREATE_ORDER_BULK_TRACKER,
    CLAIM_CREATED,
    SHOW_RETURN_ORDERS
} from "../../navigation/constants"
import {resetData} from "../../redux/createOrderSlice";
import { Loader } from '../../components/Loader';
import {cancelOrder, holdOrder, unholdOrder, payOrder, enqueueOrdersBulkCreation} from "../../services/orderServices";
import {ShowOrderContainer} from "./ShowOrderContainer";
import {UserContext} from "../../hooks/UserContext";
import {CLAIM_ENABLED_STATUSES, TRACKING_DESCRIPTIONS, TRACKING_STATUSES, TRACKING_INCIDENT_FINAL_DESCRIPTIONS} from "./constants";
import { CreateReturnContainer } from './CreateReturn/CreateReturnContainer';
import { AddRemoveStockContainer } from './CreateRemoveStock/AddRemoveStockContainer';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { FlatfileButton } from "@flatfile/react";
import { Menu, Dropdown, Tooltip } from 'antd';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { fetchStoreSkus } from "../../services/productServices";
import { fetchStoreShippingGroups } from "../../services/shippingMethodsServices"
import {CreateClaimContainer} from "./CreateClaimContainer";
import {ClaimCreatedContainer} from "./ClaimCreatedContainer";
import {RemoveStockCreatedContainer} from "./CreateRemoveStock/RemoveStockCreatedContainer";
import LogRocket from "logrocket";
import { diff_minutes } from '../../components/FormattedDate';
import { ShowReturnContainer } from '../Returns/ShowReturnContainer';
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { ORDER_STATUSES, SHIPPING_INCIDENT_CATEGORIES, SHIPPING_INCIDENT_STATUSES, TRACKING_STATUSES_DEFINITIONS } from '../../components/NewTable/StatusHighlightedDefinitions';
import { filterIsPresent } from '../../utils/filter';
import { FormattedRelativeTime } from '../../components/FormattedRelativeTime';
import { fetchOrdersSummary } from "../../services/storeServices";
import { STILL_NOT_SHIPPED_SHIPMENT_STATUSES, getOrderShippingNumber } from '../../components/utils/orderUtils';
import { OrderActionModal } from '../../components/OrderMultiselection/OrderActionModal';
import OrderMultiselectionBar from '../../components/OrderMultiselection/OrderMultiselectionBar';
import { cloneDeep } from "lodash"
import { Trans } from 'react-i18next'
import { useImpler } from '@impler/react'
import userToken from "../../redux/userToken";

const statusFilters = ORDER_STATUSES.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.status, label: status.label}
        }
)

const shippingStatusFilters = TRACKING_STATUSES_DEFINITIONS.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.status, label: status.label}
        }
)

const shippingIncidentStatusFilters = SHIPPING_INCIDENT_STATUSES.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.status, label: status.label}
        }
)
  
  const shippingIncidentCategoriesFilters = SHIPPING_INCIDENT_CATEGORIES.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.status, label: status.label}
        }
)

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ListOrderContainer = () => {
    const { t, i18n } = useTranslation();

    const {user} = useContext(UserContext)
    const history = useHistory()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const pageSize = useSelector((state) => state.pageSize.ordersList);
    const dispatch = useDispatch()
    const initialSearchParams = {
        page: 1,
        store_id: user.current_store?.id,
        per_page: pageSize,
        load_history: true,
        order_by: 'channel_created_at',
        order: 'desc'
    }
    const [searchParams, setSearchParams] = useState(initialSearchParams)
    
    const {
        isLoading: isLoadingSummary,
        isError: isErrorSummary,
        errorSummary,  
        data: dataSummary,
        isFetchingSummary,
        isPreviousDataSummary,
        refetch: refetchSummary
    } = useQuery(['home_info', user.current_store?.id], () => fetchOrdersSummary(user.current_store?.id))
    
    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        type: null
    })

    
    const { showWidget, isImplerInitiated } = useImpler({
        projectId: "673e510a998f0403d849cc0c",
        templateId: "673f8dd3998f0403d84a05ab",
        accessToken: "767eef47325bdb7f38b2c16a4902735e",
        primaryColor: '#327CFB',
        texts: {
            COMMON: {
              FINISH: i18n.t("impler.COMMON.FINISH"),
              SORRY: i18n.t("impler.COMMON.SORRY"),
              CANCEL: i18n.t("impler.COMMON.CANCEL"),
              DELETE: i18n.t("impler.COMMON.DELETE"),
              CLOSE_WIDGET: i18n.t("impler.COMMON.CLOSE_WIDGET"),
              UPLOAD_AGAIN: i18n.t("impler.COMMON.UPLOAD_AGAIN"),
            },
            STEPPER_TITLES: {
              GENERATE_TEMPLATE: i18n.t("impler.STEPPER_TITLES.GENERATE_TEMPLATE"),
              UPLOAD_FILE: i18n.t("impler.STEPPER_TITLES.UPLOAD_FILE"),
              MAP_COLUMNS: i18n.t("impler.STEPPER_TITLES.MAP_COLUMNS"),
              REVIEW_DATA: i18n.t("impler.STEPPER_TITLES.REVIEW_DATA"),
              SELECT_HEADER: i18n.t("impler.STEPPER_TITLES.SELECT_HEADER"),
              COMPLETE_IMPORT: i18n.t("impler.STEPPER_TITLES.COMPLETE_IMPORT"),
              CONFIGURE_JOB: i18n.t("impler.STEPPER_TITLES.CONFIGURE_JOB"),
              SCHEDULE_JOB: i18n.t("impler.STEPPER_TITLES.SCHEDULE_JOB"),
              CONFIRM_JOB: i18n.t("impler.STEPPER_TITLES.CONFIRM_JOB"),
              REVIEW_EDIT: i18n.t("impler.STEPPER_TITLES.REVIEW_EDIT"),
            },
            FILE_DROP_AREA: {
              DROP_FILE: i18n.t("impler.FILE_DROP_AREA.DROP_FILE"),
              CHOOSE_FILE: i18n.t("impler.FILE_DROP_AREA.CHOOSE_FILE"),
              IMAGE_FILE_SIZE: i18n.t("impler.FILE_DROP_AREA.IMAGE_FILE_SIZE"),
              FILE_FORMATS: i18n.t("impler.FILE_DROP_AREA.FILE_FORMATS"),
              FILE_SELECTED: i18n.t("impler.FILE_DROP_AREA.FILE_SELECTED"),
            },
            'PHASE0-1': {
              IMPORT_FILE: i18n.t("impler.PHASE0-1.IMPORT_FILE"),
              GENERATE_TEMPLATE: i18n.t("impler.PHASE0-1.GENERATE_TEMPLATE"),
              IMAGE_INFO_TITLE: i18n.t("impler.PHASE0-1.IMAGE_INFO_TITLE"),
              IMAGE_INFO_SUBTITLE: i18n.t("impler.PHASE0-1.IMAGE_INFO_SUBTITLE"),
            },
            PHASE1: {
              SELECT_TEMPLATE_NAME: i18n.t("impler.PHASE1.SELECT_TEMPLATE_NAME"),
              SELECT_TEMPLATE_NAME_PLACEHOLDER: i18n.t("impler.PHASE1.SELECT_TEMPLATE_NAME_PLACEHOLDER"),
              SELECT_TEMPLATE_REQUIRED_MSG: i18n.t("impler.PHASE1.SELECT_TEMPLATE_REQUIRED_MSG"),
              SELECT_SHEET_NAME: i18n.t("impler.PHASE1.SELECT_SHEET_NAME"),
              SELECT_SHEET_NAME_PLACEHOLDER: i18n.t("impler.PHASE1.SELECT_SHEET_NAME_PLACEHOLDER"),
              SELECT_SHEET_CONFIRM: i18n.t("impler.PHASE1.SELECT_SHEET_CONFIRM"),
              SELECT_SHEET_REQUIRED_MSG: i18n.t("impler.PHASE1.SELECT_SHEET_REQUIRED_MSG"),
              DOWNLOAD_SAMPLE: i18n.t("impler.PHASE1.DOWNLOAD_SAMPLE"),
              GENERATE_TEMPLATE: i18n.t("impler.PHASE1.GENERATE_TEMPLATE"),
              SEE_MAPPING: i18n.t("impler.PHASE1.SEE_MAPPING"),
              SELECT_FILE_FORMAT_MSG: i18n.t("impler.PHASE1.SELECT_FILE_FORMAT_MSG"),
              TEMPLATE_NOT_FOUND_MSG: i18n.t("impler.PHASE1.TEMPLATE_NOT_FOUND_MSG"),
              INCOMPLETE_TEMPLATE_MSG: i18n.t("impler.PHASE1.INCOMPLETE_TEMPLATE_MSG"),
            },
            SELECT_HEADER: {
              FILE_DONT_HAVE_HEADERS: i18n.t("impler.SELECT_HEADER.FILE_DONT_HAVE_HEADERS"),
              CONFIRM_AND_CONTINUE: i18n.t("impler.SELECT_HEADER.CONFIRM_AND_CONTINUE"),
              INFO: i18n.t("impler.SELECT_HEADER.INFO"),
            },
            'PHASE1-2': {
              ENTER_DATA: i18n.t("impler.PHASE1-2.ENTER_DATA"),
              RECOMMANDED_LIMIT: i18n.t("impler.PHASE1-2.RECOMMANDED_LIMIT"),
              FIX_INVALID_DATA: i18n.t("impler.PHASE1-2.FIX_INVALID_DATA"),
            },
            PHASE2: {
              REVIEW_DATA: i18n.t("impler.PHASE2.REVIEW_DATA"),
              IN_SCHEMA_TITLE: i18n.t("impler.PHASE2.IN_SCHEMA_TITLE"),
              IN_SHEET_TITLE: i18n.t("impler.PHASE2.IN_SHEET_TITLE"),
              MAPPING_NOT_DONE_TEXT: i18n.t("impler.PHASE2.MAPPING_NOT_DONE_TEXT"),
              MAPPING_DONE_TEXT: i18n.t("impler.PHASE2.MAPPING_DONE_TEXT"),
              MAPPING_FIELD_PLACEHOLDER: i18n.t("impler.PHASE2.MAPPING_FIELD_PLACEHOLDER"),
              FIELD_REQUIRED_MSG: i18n.t("impler.PHASE2.FIELD_REQUIRED_MSG"),
              ALREADY_MAPPED_MSG: i18n.t("impler.PHASE2.ALREADY_MAPPED_MSG"),
            },
            PHASE3: {
              COMPLETE: i18n.t("impler.PHASE3.COMPLETE"),
              EXPORT_DATA: i18n.t("impler.PHASE3.EXPORT_DATA"),
              RE_REVIEW_DATA: i18n.t("impler.PHASE3.RE_REVIEW_DATA"),
              ALL_RECORDS_VALID_TITLE: i18n.t("impler.PHASE3.ALL_RECORDS_VALID_TITLE"),
              ALL_RECORDS_VALID_DETAILS: i18n.t("impler.PHASE3.ALL_RECORDS_VALID_DETAILS"),
              LABEL_ALL_RECORDS: i18n.t("impler.PHASE3.LABEL_ALL_RECORDS"),
              LABEL_VALID_RECORDS: i18n.t("impler.PHASE3.LABEL_VALID_RECORDS"),
              LABEL_INVALID_RECORDS: i18n.t("impler.PHASE3.LABEL_INVALID_RECORDS"),
              REPLACE: i18n.t("impler.PHASE3.REPLACE"),
              FIND_REPLACE: i18n.t("impler.PHASE3.FIND_REPLACE"),
              ALL_COLUMNS_LABEL: i18n.t("impler.PHASE3.ALL_COLUMNS_LABEL"),
              FIND_LABEL: i18n.t("impler.PHASE3.FIND_LABEL"),
              FIND_PLACEHOLDER: i18n.t("impler.PHASE3.FIND_PLACEHOLDER"),
              REPLACE_LABEL: i18n.t("impler.PHASE3.REPLACE_LABEL"),
              IN_COLUMN_LABEL: i18n.t("impler.PHASE3.IN_COLUMN_LABEL"),
              CASE_SENSITIVE_LABEL: i18n.t("impler.PHASE3.CASE_SENSITIVE_LABEL"),
              MATCH_ENTIRE_LABEL: i18n.t("impler.PHASE3.MATCH_ENTIRE_LABEL"),
            },
            PHASE4: {
              TITLE: i18n.t("impler.PHASE4.TITLE"),
              SUB_TITLE: i18n.t("impler.PHASE4.SUB_TITLE"),
              UPLOAD_AGAIN: i18n.t("impler.PHASE4.UPLOAD_AGAIN"),
            },
            AUTOIMPORT_PHASE1: {
              MAPCOLUMN: i18n.t("impler.AUTOIMPORT_PHASE1.MAPCOLUMN"),
            },
            AUTOIMPORT_PHASE2: {
              SCHEDULE: i18n.t("impler.AUTOIMPORT_PHASE2.SCHEDULE"),
              IN_SCHEMA_TITLE: i18n.t("impler.AUTOIMPORT_PHASE2.IN_SCHEMA_TITLE"),
              IN_FEED_TITLE: i18n.t("impler.AUTOIMPORT_PHASE2.IN_FEED_TITLE"),
            },
            AUTOIMPORT_PHASE3: {
              CONFIRM: i18n.t("impler.AUTOIMPORT_PHASE3.CONFIRM"),
              INVALID_CRON_MESSAGE: i18n.t("impler.AUTOIMPORT_PHASE3.INVALID_CRON_MESSAGE"),
            },
            DELETE_RECORDS_CONFIRMATION: {
              TITLE: i18n.t("impler.DELETE_RECORDS_CONFIRMATION.TITLE"),
              DETAILS: i18n.t("impler.DELETE_RECORDS_CONFIRMATION.DETAILS"),
              CONFIRM_DELETE: i18n.t("impler.DELETE_RECORDS_CONFIRMATION.CONFIRM_DELETE"),
              CANCEL_DELETE: i18n.t("impler.DELETE_RECORDS_CONFIRMATION.CANCEL_DELETE"),
            },
            CLOSE_CONFIRMATION: {
              TITLE: i18n.t("impler.CLOSE_CONFIRMATION.TITLE"),
              DETAILS: i18n.t("impler.CLOSE_CONFIRMATION.DETAILS"),
              CONFIRM_CLOSE: i18n.t("impler.CLOSE_CONFIRMATION.CONFIRM_CLOSE"),
              CANCEL_CLOSE: i18n.t("impler.CLOSE_CONFIRMATION.CANCEL_CLOSE"),
            },
        },          
        extra: {
            store_id: user?.current_store?.id,
            auth_token: userToken?.token,
            base_url: process.env.REACT_APP_PLATFORM_URL,
            validation_errors: {
                invalid_skus: i18n.t("impler.VALIDATION_ERRORS.INVALID_SKUS"),
                invalid_shipping_config: i18n.t("impler.VALIDATION_ERRORS.INVALID_SHIPPING_CONFIG"),
                existing_order: i18n.t("impler.VALIDATION_ERRORS.EXISTING_ORDER"),
                invalid_order_number: i18n.t("impler.VALIDATION_ERRORS.INVALID_ORDER_NUMBER"),
                invalid_zip_code: i18n.t("impler.VALIDATION_ERRORS.INVALID_ZIP_CODE"),
                required_tax_regime: i18n.t("impler.VALIDATION_ERRORS.REQUIRED_TAX_REGIME"),
                required_state_registration: i18n.t("impler.VALIDATION_ERRORS.REQUIRED_STATE_REGISTRATION"),
                invalid_cnpj_length: i18n.t("impler.VALIDATION_ERRORS.INVALID_CNPJ_LENGTH"),
                invalid_cpf_length: i18n.t("impler.VALIDATION_ERRORS.INVALID_CPF_LENGTH"),
                invalid_tax_regime: i18n.t("impler.VALIDATION_ERRORS.INVALID_TAX_REGIME"),
                invalid_price: i18n.t("impler.VALIDATION_ERRORS.INVALID_PRICE"),
                invalid_price_negative: i18n.t("impler.VALIDATION_ERRORS.INVALID_PRICE_NEGATIVE"),
                invalid_discount: i18n.t("impler.VALIDATION_ERRORS.INVALID_DISCOUNT"),
                invalid_discount_no_price: i18n.t("impler.VALIDATION_ERRORS.INVALID_DISCOUNT_NO_PRICE")
            }
        },
        colorScheme: 'light',
        onUploadStart: (uploadInfo) => {
            console.log("User Started Importing", uploadInfo)
        },
        onUploadTerminate: (uploadInfo) => {
            console.log("User left Import in middle", uploadInfo)
        },
        onUploadComplete: (uploadInfo) => {
            console.log("User completed import", uploadInfo)
        },
        onWidgetClose: () => {
            console.log("Import widget is closed")
        },
        onDataImported: async (data) => {
            console.log("Data imported Validation:", data)
            
            const dynamicOutput = data.map((record) => {
                const transformedRecord = {}
            
                for (const [key, value] of Object.entries(record)) {
                    let transformedValue = value
            
                    if (key === "total_price" || key === "price_per_item" || key === "discount_per_item" || key === "shipping_price") {
                        transformedValue = parseFloat(transformedValue)
                    } else if (key === "is_cod"){
                        transformedValue = value === "Sí"
                    } else {
                        transformedValue = value
                    }
            
                    transformedRecord[key] = transformedValue
                }
            
                return transformedRecord
            });

            let batch = await enqueueOrdersBulkCreation(user.current_store?.id, dynamicOutput)
            console.log("Orders Bulk Creation...", dynamicOutput)
            console.log("batch", batch)
            history.push(storePath + CREATE_ORDER_BULK_TRACKER.replace(':id', batch.batch_id))
            return i18n.t("orders.addContainer.imported")
        }
    })

    const handleImport = async () => {
        window.analytics.track('Orders - Starts Creating', {type: "REGULAR ORDER BULK"})

        let schemaFields = [
            {
                "key": "order_number",
                "name": i18n.t("orders.fileFields.order_number"),
                "type": "String",
                "validations": [],
                "description": "",
                "alternateKeys": ["ID", "Número de Orden", "Número de Pedido", "Pedido"],
                "isRequired": true,
                "isUnique": false,
                "isFrozen": false,
                "selectValues": []
            },
            {
                "key": "product_sku",
                "name": "SKU",
                "type": "String",
                "validations": [],
                "description": "",
                "alternateKeys": ["produto", "producto"],
                "isRequired": true,
                "isUnique": false,
                "isFrozen": false,
                "dateFormats": [],
                "selectValues": []
            },
            {
                "key": "product_quantity",
                "name": i18n.t("orders.fileFields.quantity"),
                "type": "Number",
                "validations": [],
                "description": "",
                "alternateKeys": ["qty"],
                "isRequired": true,
                "isUnique": false,
                "isFrozen": false,
                "selectValues": []
            }
        ]

        if (user.current_store.warehouses[0].country === "BR") {
            schemaFields.push(
                {
                    "key": "price_per_item",
                    "name": i18n.t("orders.fileFields.unit_price"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["unit_price"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "discount_per_item",
                    "name": i18n.t("orders.fileFields.discount_per_item"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["desconto"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_price",
                    "name": i18n.t("orders.fileFields.shipping_price"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["preco_frete"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_name",
                    "name": i18n.t("orders.fileFields.name"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Nombre", "nome", "Razao Social"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_lastname",
                    "name": i18n.t("orders.fileFields.last_name"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Apellido", "Last Name"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "billing_tax_id",
                    "name": i18n.t("orders.fileFields.cpf_cnpj"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["cpf", "cnpj"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "billing_state_registration",
                    "name": i18n.t("orders.fileFields.state_estadual"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["inscrição"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "billing_company",
                    "name": "Companhia",
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": [],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_email",
                    "name": i18n.t("orders.fileFields.shipping_email"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Email", "E-mail"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_phone",
                    "name": i18n.t("orders.fileFields.shipping_phone"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Teléfono"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_address_1",
                    "name": i18n.t("orders.fileFields.shipping_address1"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Dirección 1", "logradouro", "rua"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_address_2",
                    "name": i18n.t("orders.fileFields.shipping_address2"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["complemento"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_number",
                    "name": i18n.t("orders.fileFields.shipping_number"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["numero"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_neighborhood",
                    "name": i18n.t("orders.fileFields.shipping_neighborhood"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["bairro"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_city",
                    "name": i18n.t("orders.fileFields.shipping_city"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["ciudad", "cidade"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_state",
                    "name": i18n.t("orders.fileFields.shipping_state"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Estado"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_zipcode",
                    "name": i18n.t("orders.fileFields.shipping_zip_code"),
                    "type": "String",
                    "validations": [
                        {
                            "validate": "length",
                            "min": 8,
                            "max": 9,
                            "errorMessage": "Zip code must have 8 digits"
                        }
                    ],
                    "description": "",
                    "alternateKeys": ["Código Postal", "Codigo Postal", "cep"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_country",
                    "name": i18n.t("orders.fileFields.shipping_country"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["País, Pais"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_configuration_name",
                    "name": i18n.t("orders.fileFields.shipping_configuration"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Carrier", "Envio", "frete"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "order_type",
                    "name": i18n.t("orders.fileFields.order_type"),
                    "type": "Select",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["doação ou venda", "canal"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": ["SALE", "DONATION"]
                },
                {
                    "key": "billing_tax_regime",
                    "name": i18n.t("orders.fileFields.billing_tax_regime"),
                    "type": "Select",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Regime", "regime fiscal (fisico/legal"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": ["PHYSICAL", "LEGAL"]
                },
            )
        } else {
            schemaFields.push(
                {
                    "key": "shipping_name",
                    "name": i18n.t("orders.fileFields.name"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Nombre"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_lastname",
                    "name": i18n.t("orders.fileFields.last_name"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Apellido", "Last Name"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_email",
                    "name": i18n.t("orders.fileFields.shipping_email"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Email", "E-mail"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_phone",
                    "name": i18n.t("orders.fileFields.shipping_phone"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Teléfono"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_address_1",
                    "name": i18n.t("orders.fileFields.shipping_address1"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Dirección 1"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_address_2",
                    "name": i18n.t("orders.fileFields.shipping_address2"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": [],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_city",
                    "name": i18n.t("orders.fileFields.shipping_city"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Dirección 2"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_state",
                    "name": i18n.t("orders.fileFields.shipping_state"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Estado"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_zipcode",
                    "name": i18n.t("orders.fileFields.shipping_zip_code"),
                    "type": "String",
                    "validations": [
                        /*{
                            "validate": "length",
                            "min": 5,
                            "max": 6,
                            "errorMessage": "Zip code must have 5 digits"
                        }*/
                    ],
                    "description": "",
                    "alternateKeys": ["Código Postal", "Codigo Postal", "cep"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_country",
                    "name": i18n.t("orders.fileFields.shipping_country"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["País, Pais"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "shipping_configuration_name",
                    "name": i18n.t("orders.fileFields.shipping_configuration"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Carrier", "Envio"],
                    "isRequired": true,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "is_cod",
                    "name": i18n.t("orders.fileFields.is_cod"),
                    "type": "Select",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Is COD"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": ["Sí", "No"],
                    "defaultValue": "No"
                },
                {
                    "key": "billing_tax_id",
                    "name": i18n.t("orders.fileFields.tax_id"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["tax id"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "price_per_item",
                    "name": i18n.t("orders.fileFields.unit_price"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["unit_price"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "discount_per_item",
                    "name": i18n.t("orders.fileFields.discount_per_item"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["descuento"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "currency",
                    "name": i18n.t("orders.fileFields.currency"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["moneda", "divisa", "Divisa"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                },
                {
                    "key": "total_price",
                    "name": i18n.t("orders.fileFields.total_price"),
                    "type": "String",
                    "validations": [],
                    "description": "",
                    "alternateKeys": ["Total price","Precio total"],
                    "isRequired": false,
                    "isUnique": false,
                    "isFrozen": false,
                    "selectValues": []
                }
            ) 
        }

        showWidget({
          schema: schemaFields,
          output: {
            page: "{{page}}",
            chunkSize: "{{chunkSize}}",
            isInvalidRecords: "{{isInvalidRecords}}",
            template: "{{template}}",
            uploadId: "{{uploadId}}",
            fileName: "{{fileName}}",
            extra: "{{extra}}",
          },
        })
      }

    const [exportDialogOpen, setExportDialogOpen] = useState(false)
    const [enqueueingExport, setEnqueueingExport] = useState(false)
    const [exportError, setExportError] = useState(null)
    const [exportNotificationOpen, setExportNotificationOpen] = useState(false)
    const [exportProgress, setExportProgress] = useState(0)
    const [exportFileUrl, setExportFileUrl] = useState(null)
    const [showOrderDetailBusy, setShowOrderDetailBusy] = useState(false)
    const [productsDetail, setProductsDetail] = useState(true)

    const [createdClaimMotive, setCreatedClaimMotive] = useState(null)
    const [orderNumber, setOrderNumber] = useState(null)

    const [selectedOrders, setSelectedOrders] = useState([])
    const [deselectOrders, setDeselectOrders] = useState(false)

    const [orderActionModalProps, setOrderActionModalProps] = useState({open: false, action:null, actionParameters:null})

    const CreateClaimSlidePanelRef = useRef(null)
    const ClaimCreatedSlidePanelRef = useRef(null)
    const EditOrderSlidePanelRef = useRef(null)
    const DuplicateOrderSlidePanelRef = useRef(null)
    const AddOrderSlidePanelRef = useRef(null)
    const createReturnSlidePanelRef = useRef(null)
    const CreateRemoveStockSlidePanelRef = useRef(null)
    const EditRemoveStockSlidePanelRef = useRef(null)
    const RemoveStockCreatedSlidePanelRef = useRef(null)

    const { 
        data: channelIntegrationsData,
        isLoading: isLoadingChannelIntegrations,
        isPreviousData: isPreviousDataChannelIntegrations
    } = useQuery(['channels', user.current_store?.id], () => fetchChannels({store_id: user.current_store?.id, only_name: true}), {
        keepPreviousData : true,
        refetchOnWindowFocus: false
    })

    const {   
        data: shippingMethodsDataGroup, 
        isLoading: isLoadingShippingMethodsGroup ,
        isError: isErrorShippingMethodsGroup,
        error: errorShippingMethodsGroup,
        isFetching: isFetchingShippingMethodsGroup,
        isPreviousData: isPreviousDataShippingMethodsGroup,
        refetch: refetchShippingMethodsGroup
    } = useQuery(['shipping_methodsGroup', user.current_store?.id],() => fetchStoreCubboShippingMethodsGroup(user.current_store?.id), { 
        keepPreviousData : true,
        refetchOnWindowFocus: false
    })

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
        refetch,
    } = useQuery(['orders', searchParams], () => fetchOrders(searchParams), { keepPreviousData : true })

    useEffect(() => {
        let newSearchParams = {...searchParams, store_id: user.current_store?.id}
        setSearchParams(newSearchParams)
    }, [user.current_store?.id])
    

    const handlerOnClickStatus = (order) => {
        history.push(storePath + SHOW_ORDERS.replace(':id', order.id))
    }

    const [shippingMethodFilters, setShippingMethodFilters] = useState([])
    const [channelIntegrationsFilters, setChannelIntegrationsFilters] = useState([])

    const filtersInitialData = [
        {
          key: "order_number",
          label: "orders.filters.order_number",
          icon: "order_number",
          data: {
                visible: true,
                type: 'text',
                value: ''
          },
        },
        {
            key: "shipping_number",
            label: "orders.filters.shipping_number",
            icon: "shipping_label_number",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "shipping_name",
            label: "orders.filters.shipping_name",
            icon: "order_buyer",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "is_delayed",
            label: "orders.filters.is_delayed",
            icon: "order_delayed",
            data: {
                visible: false,
                type: 'boolean',
                onClickValue: true
            },
        },
        {
            key: "sku",
            label: "orders.filters.sku",
            icon: "order_sku",
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        },
        {
            key: "channel_name",
            label: "orders.filters.channel_name",
            placeholder: "orders.filters.channel_name_placeholder",
            icon: "sales_channel",
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                options: channelIntegrationsFilters,
            },
        },
        {
            key: "carrier_name",
            label: "orders.filters.carrier_name",
            placeholder: "orders.filters.shipping_method_placeholder",
            icon: "shipping_method",
            data: {
                visible: false,
                type: 'multiFilter',
                value: [],
                options: shippingMethodFilters,
            },
        },
        {
            key: "status",
            label: "orders.filters.status",
            placeholder: "orders.filters.status_placeholder",
            icon: 'order_status',
            data: {
                visible: false,
                type: 'multiFilter',
                value: [],
                options: statusFilters,
            },
        },
        {
            key: "shipping_status",
            label: "orders.filters.shipping_status",
            placeholder: "orders.filters.shipping_status_placeholder",
            icon: 'order_shipping_status',
            data: {
                visible: false,
                type: 'multiFilter',
                value: [],
                options: shippingStatusFilters,
            },
        },
        {
            key: "shipping_date",
            label: "orders.filters.shipping_date",
            icon: "order_shipping_date",
            data: {
                visible: false,
                type: 'date',
                value: null
            },
        },
        {
            key: "channel_created_at",
            label: "orders.filters.channel_created_at",
            icon: "order_channel_created_at",
            data: {
                visible: false,
                type: 'date',
                value: null
            },
        },
        {
            key: "with_shipping_incident",
            label: "orders.filters.shipping_incident",
            icon: 'order_shipping_incident',
            data: {
                visible: false,
                type: 'boolean',
                onClickValue: true,
            },
            group: 'shipping_incident',
            index: 0,
          },
          {
            key: "shipping_incident_category",
            label: "orders.filters.shipping_incident_category_placeholder",
            placeholder: "orders.filters.shipping_incident_category_placeholder",
            data: {
                visible: false,
                type: 'multiFilter',
                value: [],
                options: shippingIncidentCategoriesFilters
            },
            group: 'shipping_incident',
            index: 1
          },
          {
            key: "shipping_incident_status",
            label: "orders.filters.shipping_incident_status_placeholder",
            placeholder: "orders.filters.shipping_incident_status_placeholder",
            data: {
                visible: false,
                type: 'multiFilter',
                value: [],
                options: shippingIncidentStatusFilters
            },
            group: 'shipping_incident',
            index: 2
          },
          {
            key: "is_click_and_collect",
            label: "orders.filters.order_click_and_collect",
            icon: "order_click_and_collect",
            data: {
                visible: false,
                type: 'boolean',
                onClickValue: true
            },
        },
    ]

    const [filtersData, setFiltersData] = useState(filtersInitialData)
    
    useEffect(() => { // Update the filters option when the shipping methods data is loaded
        const shipping_methods = Object.keys(shippingMethodsDataGroup || {}).map((ship)=> {
            return {
                id: ship,
                label: ship
            }
        })
        setShippingMethodFilters(shipping_methods)
        updateFilterOptions(shipping_methods, "carrier_name")
    }, [shippingMethodsDataGroup])

    useEffect(() => { // Update the filters option when the shipping methods data is loaded
        const channel_integrations = channelIntegrationsData?.channel_integrations.map((channel)=> {
            return {
                id: channel.channel_type_name,
                label: capitalizeFirstLetter(channel.channel_type_name)
            }
        })
        channel_integrations?.push({id: 'manual', label: 'Cubbo'})
        setChannelIntegrationsFilters(channel_integrations)
        const filterItem = filtersData.find(item => item.key === "channel_name")
        if (filterItem) {
            filterItem.data.options = channel_integrations
            updateFiltersData(filterItem.data, "channel_name")
        }
    }, [channelIntegrationsData])

    useEffect(() => {
        window.analytics.page('Orders', 'List')
    }, [])

    

    const onSortChange = (orderBy) => {
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc',
                page: 1
            })
        }
        else {
            if (searchParams.order_by != initialSearchParams.order_by || searchParams.order != initialSearchParams.order) {
                setSearchParams({
                    ...searchParams,
                    order_by: initialSearchParams.order_by, 
                    order: initialSearchParams.order,
                    page: 1
                })
            }
        }
    }

    const callFilterShortcut = (filters, event_name) => {
        window.analytics.track(event_name)
        const newSearchParams = {...searchParams}
        filters.forEach(filter => {
            let currentFilter = filtersData.find(item => item.key == filter.key)
            currentFilter.data.value = filter.value
            currentFilter.data.visible = true
            let filterKey = currentFilter.key_alias ? currentFilter.key_alias : currentFilter.key
            newSearchParams[filterKey] = filter.value
        })
        setSearchParams(newSearchParams)
    }

    const updateFilterOptions = (options, filter) => {
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key === filter) 
                    return {
                        ...item,
                        data: {...item.data, options: options}
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
      }

    const updateFiltersData = (updatedData, filter, override = false) => {
        // Update filters with new data
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter) 
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })

        // Set search params only if needed
        const filterItem = filtersData.find(item => item.key == filter)
        if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length == 0)) return
        if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
        let value = updatedData.value
        const filter_name = filterItem.key_alias || filterItem.key
        
        if (["channel_created_at", "shipping_date"].includes(filter) && value?.from && value?.to) {
            const from = new Date(value.from)
            const to = new Date(value.to)
            setSearchParams((prev) => ({...prev, 
                [filter]: {
                    from: from.toISOString(),
                    to: to.toISOString()
                }, page: 1
            }))
        }
        else {
            if (!value) {
                setSearchParams((prev) => {
                    // Delete filter key from searchParams
                    const newSearchParams = {...prev, page: 1}
                    delete newSearchParams[[filter_name]]
                    return newSearchParams
                })
            }
            else {
                setSearchParams((prev) => ({...prev, [filter_name]: value, page: 1}))
            }
        }
    }

    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const handleResetFilters = () => {
        setSearchParams(initialSearchParams)
    }   

    const table_columns = [
        {
            Header: 'orders.list.order_number',
            description: 'orders.list.channel_name_items',
            accessor: 'order_number', // accessor is the "key" in the data
        },
        {
            Header: 'orders.list.status',
            description: 'orders.list.status_desc',
            accessor: 'status', // accessor is the "key" in the data
        },
        {
            Header: 'orders.list.shipping_status',
            description: 'orders.list.shipping_status_desc',
            accessor: 'shipping_status', // accessor is the "key" in the data
        },
        {
            Header: "orders.list.full_name",
            accessor: 'full_name',
        },
        {
            Header: "",
            accessor: 'actions', // accessor is the "key" in the data
            fetchingIndicator : true,
            disableSortBy: true
        },
    ]

    const wrapWithIndigoSpan = text => (<span className="text-indigo-600 text-base">{text}</span>)

    const orderNumberToExportDescription = orderNumber => (
        <>
            {i18n.t('orders.list.export_dialog.order_number')}
            {wrapWithIndigoSpan(orderNumber)}
        </>
    )

    const shippingNumberToExportDescription = trackingNumber => (
        <>
            {i18n.t('orders.list.export_dialog.tracking_number')}
            {wrapWithIndigoSpan(trackingNumber)}
        </>
    )

    const channelNameToExportDescription = channel_name => (
        <>
            {i18n.t('orders.list.export_dialog.channel_name')}
            {channel_name && wrapWithIndigoSpan(
                channel_name
                    ?.map(channel_name => channel_name === 'manual' ? 'Cubbo' : capitalizeFirstLetter(channel_name))
                    .join(', ')
            )}
        </>
    )
    const statusToExportDescription = statuses => (
        <>  
            {i18n.t('orders.list.export_dialog.status', { count: statuses?.length })}
            {statuses && wrapWithIndigoSpan(
                statuses
                    ?.map(status => i18n.t(ORDER_STATUSES.find(orderStatusData => orderStatusData.status === status).label))
                    .join(', ')
            )}
        </>
    )
    const shippingStatusToExportDescription = shippingStatuses => (
        <>
            {i18n.t('orders.list.export_dialog.shipping_status', { count: shippingStatuses?.length })}
            {shippingStatuses && wrapWithIndigoSpan(
                shippingStatuses
                    ?.map(status => i18n.t(TRACKING_STATUSES_DEFINITIONS.find(trackingStatusData => trackingStatusData.status === status).label))
                    .join(', ')
            )}
        </>
    )
    const shippingNameToExportDescription = shippingName => (
        <>
            {i18n.t('orders.list.export_dialog.shipping_name')}
            {wrapWithIndigoSpan(shippingName)}
        </>
    )
    const shippingDateToExportDescription = function (range) {
        const from = new Date(range?.from).toLocaleString('en-GB', 
            {year: "2-digit",
            month: "numeric",
            day: "2-digit"
        })

        const to = new Date(range?.to).toLocaleString('en-GB', 
            {year: "2-digit",
            month: "numeric",
            day: "2-digit"
        })

        return (
            <>
                {i18n.t('orders.list.export_dialog.shipping_date')}
                {wrapWithIndigoSpan(from)}
                {i18n.t('orders.list.export_dialog.shipping_date_join')}
                {wrapWithIndigoSpan(to)}
            </>
        )
    }
    const shippingMethodToExportDescription = shippingMethod =>  (
        <>
            {i18n.t('orders.list.export_dialog.shipping_method')}
            {wrapWithIndigoSpan(shippingMethod)}
        </>
    )

    const channelCreatedAtToExportDescription = function (range) {
        const fromDate = new Date(range?.from).toLocaleString('en-GB', {year: "2-digit", month: "numeric", day: "2-digit"})
        const toDate = new Date(range?.to).toLocaleString('en-GB', {year: "2-digit", month: "numeric", day: "2-digit"})

        return (
            <>
                {i18n.t('orders.list.export_dialog.channel_created_at')}
                {wrapWithIndigoSpan(fromDate)}
                {i18n.t('orders.list.export_dialog.channel_created_at_join')}
                {wrapWithIndigoSpan(toDate)}
            </>
        )
    }

    const skuToExportDescription = skus => (
        <>
            {i18n.t('orders.list.export_dialog.sku')}
            {wrapWithIndigoSpan(skus)}
        </>
    )

    const shippingIncidentCategoryExportDescription = shippingIncidentCategories =>  (
         <>
            {i18n.t('orders.list.export_dialog.shipping_incident_category', { count: shippingIncidentCategories?.length })}
            {shippingIncidentCategories && wrapWithIndigoSpan(
                shippingIncidentCategories
                    ?.map(status => i18n.t(SHIPPING_INCIDENT_CATEGORIES.find(shippingIncidentCategoyData => shippingIncidentCategoyData.status === status).label))
                    .join(', ')
            )}
        </>
    )

    const shippingIncidentStatusExportDescription = shippingIncidentStatuses =>  (
        <>
        {i18n.t('orders.list.export_dialog.shipping_incident_status', { count: shippingIncidentStatuses?.length })}
        {shippingIncidentStatuses && wrapWithIndigoSpan(
            shippingIncidentStatuses
                ?.map(status => i18n.t(SHIPPING_INCIDENT_STATUSES.find(shippingIncidentStatusData => shippingIncidentStatusData.status === status).label))
                .join(', ')
        )}
        </>
    )

    const withShippingIncidentExportDescription = isShippingIncident => (
        <>
            {i18n.t('orders.list.export_dialog.with_shipping_incident')}
            {wrapWithIndigoSpan(i18n.t('orders.shipping_incident.with_shipping_incident'))}
        </>
    )

    const handleClaimCreationSuccess = function (order_id, category) {
        setCreatedClaimMotive(category)
        history.push(storePath + CLAIM_CREATED.replace(':order_id', order_id))
    }

    const handleAddRemoveStockSuccess = function (order_number) {
        setOrderNumber(order_number)
        history.push(storePath + REMOVE_STOCK_CREATED)
    }

    const primaryButton = {
        backgroundColor: "#327cfb",
        color: "white",
        border: "1px solid #00c6ff",
        padding: "6px 16px",
        fontSize: "15px",
        borderRadius: "8px",
        ":hover": {
          backgroundColor: "#0D93BA",
          border: "1px solid #0D93BA"
        }
    }

    const ordersExportDescriptionBuilders = {
        'order_number': orderNumberToExportDescription,
        'shipping_number': shippingNumberToExportDescription,
        'channel_name': channelNameToExportDescription,
        'status': statusToExportDescription,
        'shipping_status': shippingStatusToExportDescription,
        'shipping_name': shippingNameToExportDescription,
        'shipping_date': shippingDateToExportDescription,
        'carrier_name': shippingMethodToExportDescription,
        'sku': skuToExportDescription,
        'channel_created_at': channelCreatedAtToExportDescription,
        'with_shipping_incident': withShippingIncidentExportDescription,
        'shipping_incident_category': shippingIncidentCategoryExportDescription,
        'shipping_incident_status': shippingIncidentStatusExportDescription,
    }

    const ExportDescription = () => {
        let filters = {...searchParams}
        if (!filters || !Object.keys(filters).length) {
            return (<div className="text-sm text-gray-500">{i18n.t('orders.list.export_dialog.default_description')}</div>)
        }
        
        filters =  Object.entries(filters).reduce((acc, [k, v]) => filterIsPresent(v) ? {...acc, [k]:v} : acc , {})
        const filtersDescriptions = Object.keys(filters)
            .filter(filterKey => ordersExportDescriptionBuilders[filterKey] instanceof Function)
            .map(filterKey => ordersExportDescriptionBuilders[filterKey](filters[filterKey]))

        if (filtersDescriptions.length) {
            return (
                <>
                    <div className="mt-3 text-base sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="text-gray-500 mb-3">{i18n.t('orders.list.export_dialog.intro')}</div>
                        <ul className="list-disc ml-6">
                            {filtersDescriptions.map((description, index) => <li key={index}>{description}</li>)}
                        </ul>
                    </div>
                </>
            )
        } else {
            return (<div>{i18n.t('orders.list.export_dialog.default_description')}</div>)
        }
    }

    const MemoizedExportDescription = useMemo(ExportDescription, [searchParams, t])

    const onConfirmDialogHandler = async () => {
        setDialog({...dialog, loading: true})
        switch (dialog.type){
            case "hold":
                window.analytics.track('Orders - Pauses', {id: dialog.order_id})
                await holdOrder(dialog.order_id)
                break
            case "unhold":
                window.analytics.track('Orders - Unpauses', {id: dialog.order_id})
                await unholdOrder(dialog.order_id)
                break
            case "cancel":
                window.analytics.track('Orders - Cancels', {id: dialog.order_id})
                await cancelOrder(dialog.order_id)
                break
            case "interrupt_shipment":
                window.analytics.track('Orders - Cancels', {id: dialog.order_id})
                const notes = i18n.t("orders.list.interrupt_notes", {
                    order_number: dialog.order_number,
                    order_id: dialog.order_id,
                    user_email: dialog.user_email})
                await cancelOrder(dialog.order_id, notes)
                break
            case "paid":
                window.analytics.track('Orders - Marks as Paid', {id: dialog.order_id})
                await payOrder(dialog.order_id)
                break
        }
        setDialog({...dialog, loading: false, open: false})
        refetchAll()
    }
    
    const refetchAll = () => {
        refetch()
        refetchSummary()
    }

    const onConfirmExport = async function () {

        window.analytics.track('Orders - Starts Exporting')
        setEnqueueingExport(true)
        setExportFileUrl(null)

        const { job_id } = await enqueueOrdersExport({
            ...searchParams,
            products_detail: productsDetail,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })

        watchExportProgress(job_id)

        setExportDialogOpen(false)
        setExportNotificationOpen(true)
        setEnqueueingExport(false)
    }

    const watchExportProgress = function (job_id) {
        const intervalId = setInterval(async () => {
            const {progress_percentage: progressPercentage, download_url: downloadUrl} = await getOrdersExport(job_id)

            if (progressPercentage < 100) {
                setExportProgress(progressPercentage)
            } else {
                setExportFileUrl(downloadUrl)
                setExportProgress(0)
                clearInterval(intervalId);
            }
        }, 1000)
    }
    
    // Notification Dialog
    const handleExportDownload = function () {
        setExportNotificationOpen(false)
        window.analytics.track('Orders - Downloads Exported')
        window.open(process.env.REACT_APP_PLATFORM_URL + exportFileUrl)
    }
    
    const handleCreateClaimClick = function (order) {
        LogRocket.track('CreateClaim', {
            order_id: order.id,
            store_id: order.store_id,
            store_name: user.current_store?.name
        });
        window.analytics.track('Claims - Starts Creating', {order_id: order.id,})
        history.push(storePath + CREATE_CLAIM.replace(':order_id', order.id))
    }

    const getMenuOptions = (order) => {
        const options = [
            {
                title: i18n.t("orders.list.show_orders"),
                clickHandler: ()=> history.push(storePath + SHOW_ORDERS.replace(':id', order.id))
            }
        ]

        if(order.order_type!=="REMOVE_STOCK"){
            options.push({
                title: i18n.t("orders.list.duplicate_order"),
                clickHandler: () => {
                    window.analytics.track('Orders - Starts Duplicating', {id: order.id})
                    history.push(storePath + DUPLICATE_ORDER.replace(':id', order.id))
                }
            })
        }

        if(order.status!=="shipped" && order.status!=="canceled" && order.status!=="interrupted" && order.status!=="returning" && order.status!=="returned" && order.status!=="return_arrived" && order.status!=="reentered" && !order.pick_n_ship_status){
            if(["REMOVE_STOCK", "REMOVE_DAMAGED_STOCK", "REMOVE_EXPIRED_STOCK"].includes(order.order_type)){
                options.push({
                    title: i18n.t("orders.list.edit_order"),
                    clickHandler: () => {
                        window.analytics.track('Orders - Starts Editing', {id: order.id, type: "REMOVAL ORDER"})
                        history.push(storePath + EDIT_ORDER_REMOVE_STOCK.replace(':id', order.id))
                    }
                 })
            }else{
                options.push({
                    title: i18n.t("orders.list.edit_order"),
                    clickHandler: () => {
                        window.analytics.track('Orders - Starts Editing', {id: order.id, type: "REGULAR ORDER"})
                        history.push(storePath + EDIT_ORDER.replace(':id', order.id))
                    }
                 })
            }
            options.push(
            {
             title: i18n.t("orders.list.cancel_order"), 
             clickHandler: () => setDialog({...dialog,
                 open: true,
                 title: i18n.t("orders.list.cancel_order"),
                 description: <>
                                {i18n.t("orders.list.sure_to_cancel")}
                                {order.is_difal_paid &&
                                    <div className='mt-2 text-yellow-600'>
                                        {i18n.t("orders.list.sure_to_cancel_with_difal")}
                                    </div>
                                }
                            </>,
                 type: "cancel",
                 order_id: order.id
             })
            }
            )
        }
        if(order.status == "shipped" && !order.requires_external_label){
            if (!order.shipping_status || Object.values(STILL_NOT_SHIPPED_SHIPMENT_STATUSES).includes(order.shipping_status)){
                options.push({
                    title: i18n.t("orders.list.cancel_order"),
                    clickHandler: () => setDialog({...dialog,
                        open: true,
                        title: i18n.t("orders.list.cancel_order"),
                        description: <>
                                        <>
                                            {i18n.t("orders.list.sure_to_interrupt_shipment")}
                                        </>
                                        <div className='text-red-600 bg-red-50 rounded-md p-1 inline-block mt-2 p-4 flex flex-col space-y-4'>
                                            <Trans i18nKey="orders.list.interrupt_shipment_disclaimer"/>
                                        </div>
                                    </>,
                        type: "interrupt_shipment",
                        order_id: order.id,
                        order_number: order.order_number,
                        user_email: user.email
                    })
                })
            }
        }
        if((order.status=="pending" || order.status=="backorder" || order.status=="error" ) && !order.pick_n_ship_status){
            options.push({
                title: i18n.t("orders.list.pause_order"),
                clickHandler: () => setDialog({...dialog,
                    open: true,
                    title: i18n.t("orders.list.pause_order"),
                    description: i18n.t("orders.list.sure_to_pause"),
                    type: "hold",
                    order_id: order.id
                })
            })
        }

        if (CLAIM_ENABLED_STATUSES.includes(order.status)) {
            options.push({
                title: i18n.t("orders.list.create_claim"),
                clickHandler: () => handleCreateClaimClick(order)
            })
        }

        const isBrCustomer = user.current_store.warehouses[0].country === "BR"

        const show = !isBrCustomer || isBrCustomer && user.roles.findIndex(roll => {return  roll=="ROLE_SUPER_ADMIN"}) != -1
        const isShipped = order.status === "shipped"
        const isDelivered = order.shipping_status === "shipment_delivered"
        const hasNoTracking = ['SERVIENTREGA'].includes(order.shipping_method?.carrier_name) || order.shipping_method?.delivery_type === 'Pickup'

        if (show && !order.is_pick_and_ship && isShipped && (isDelivered || hasNoTracking)) {
            options.push({
                title: i18n.t("orders.list.create_return"),
                clickHandler: () => {
                    window.analytics.track('Returns - Starts Creating', {id: order.id})
                    history.push(storePath + CREATE_RETURN.replace(':order_id', order.id))
                }
            })
        }

        return options
    }

    const buildOrderNumberColumn = function (order) {
        return (
            <>
                <div className="font-normal text-base text-gray-700">
                    #{String(order.order_number)}
                </div>
                {order.cart_id && order.order_number !== order.cart_id.toString() &&
                    <div className="mt-1 text-sm">
                        Cart {String(order.cart_id)}
                    </div>
                }
                {order.channel_integration?.channel_type_name ?
                <>
                    <div className="mt-1 text-sm text-gray-400">
                        De {capitalizeFirstLetter(order.channel_integration?.channel_type_name)} |
                        <div className="inline ml-1">
                            {order.quantity_of_items}
                            <div className="inline ml-1">
                                {i18n.t("orders.list.items")}
                            </div>
                        </div>
                    </div>
                    {order.channel_integration?.channel_type_name === "woocommerce" &&
                        <div className="mt-1 text-sm">
                            {order.channel_integration?.config.domain}
                        </div>
                    }
                </>
                    : <div className="mt-1 text-sm text-gray-400">
                        De Cubbo |
                        <div className="inline ml-1">
                            {order.quantity_of_items}
                            <div className="inline ml-1">
                                {i18n.t("orders.list.items")}
                            </div>
                        </div>
                    </div>
                }
                {['REMOVE_STOCK', 'REMOVE_DAMAGED_STOCK', 'REMOVE_EXPIRED_STOCK'].includes(order.order_type) &&
                    <div className="whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block mt-1.5">{i18n.t(`orders.showContainer.${order.order_type.toLowerCase()}_type`)}</div>
                }
            </>
        )
    }

    const getShippingMethodColumn = (order) => {
        const shippingNumber = getOrderShippingNumber(order?.shipping_labels)

        return (
        <div>
            <div className='font-normal text-base text-gray-700'>
                { String(order.shipping_method==null ? "-":` ${order.shipping_method.carrier_name}`) } 
                { order.is_cod &&
                    <b> - COD</b>
                }
            </div>

            <a
                onClick={(e)=> e.stopPropagation()}
                className="mt-0.5 hover:underline block"
                href={order.cubbo_tracking_url}
                target="_blank"
                rel="noreferrer"
            >
                {shippingNumber ? shippingNumber : i18n.t("orders.tracking_link_without_label")}
            </a>

            {order.is_click_and_collect &&
                <div className="whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block mt-1.5">{i18n.t("orders.showContainer.is_click_and_collect")}</div>
            }
            
        </div>
        )
    }

    const buildStatusHighlighted = function(order) {
        if (!order) return <></>

        let orderStatus = order.is_pick_and_ship && order.pick_n_ship_status && order.virtual_status === 'pending' ? order.pick_n_ship_status : order.virtual_status
        // Add waiting_pick_up to oms orders view, except for MOOVA
        orderStatus = orderStatus === 'hold' ? 'holded' : orderStatus  // temporary fix: change hold to holded

        let return_cause = null
        if (order.return?.return_type && ['returning', 'returned', 'reentered'].includes(orderStatus)) {
            return_cause = order.return?.return_type === "RETURNED_BY_CARRIER" ? "returned_by_carrier" : "returned_by_client"
        }
        
        if (orderStatus === 'pending' && order.pending_at) {
            var minutes = diff_minutes(new Date, new Date(order.pending_at))
            var hours = Math.trunc(minutes/60)
            if (hours > 24) {
                var days = Math.trunc(hours/24)
                minutes = 0
                hours = 0
            }
        }
        return (
        <div order={order}>
            <StatusHighlighted
                className=""
                status={orderStatus}
                statuses={ORDER_STATUSES}
                onClick={(e)=> {
                    e.stopPropagation()
                    handlerOnClickStatus(order)
                }}
                description={return_cause}
            />
            {order.order_date && ['initial', 'empty', 'entirely_dropshipping', 'cancelation_window', 
                'backorder', 'error'].includes(orderStatus) ?
                <>
                    <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                        <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                        <FormattedDate date={order.order_date} shortDate={true}/>
                    </div>
                </>
            : order.pending_at && orderStatus==='pending' ?
            <div className="mt-2 ml-0.5">
                 <div className={`text-sm ${(days >= 1 && order.quantity_of_items <= 100) || (days >= 3 && order.quantity_of_items <= 500) 
                    || (days >= 4 && order.quantity_of_items <= 2000)
                    ? 'text-red-400' : ''}`}>
                    <FormattedRelativeTime date={order.pending_at} />
                </div> 
            </div>
            : orderStatus === 'holded' ?
            <div className='mt-4'>
                    <a className="border border-gray-300 shadow-sm rounded-md p-1.5 text-gray-700 
                        font-normal hover:bg-gray-100 hover:text-gray-700 hover:border-blue-400"
                        onClick={(e) => {
                            e.stopPropagation()
                            setDialog({...dialog,
                                open: true,
                                title: i18n.t("orders.list.reset_order"),
                                description: i18n.t("orders.list.sure_to_reboot"),
                                type: "unhold",
                                order_id: order.id
                            })}
                        }>
                        {i18n.t("orders.list.reset_order")}
                    </a>
            </div>
            : orderStatus === 'unpaid' ?
            <div className='mt-1.5'>
                <span className='text-gray-400 text-sm'><FormattedRelativeTime date={order.order_date} /></span>
                <a className="mt-2 max-w-min block border border-gray-300 shadow-sm rounded-md p-1.5 text-gray-700 
                    font-normal hover:bg-gray-100 hover:text-gray-700 hover:border-blue-400"
                    onClick={(e) =>  {
                        e.stopPropagation()
                        setDialog({...dialog,
                            open: true,
                            title: i18n.t("orders.list.pay_order"),
                            description: i18n.t("orders.list.sure_to_pay"),
                            type: "paid",
                            order_id: order.id
                        })}
                    }>
                    {i18n.t("orders.list.pay_order")}
                </a>
            </div>
            : orderStatus === 'waiting_pick_up' ?
            <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                <FormattedDate date={order.shipping_date} shortDate={true}/>
            </div>
            : ['returning', 'returned', 'return_arrived', 'reentered'].includes(orderStatus) ?
            <div className='mt-4'>
                <div className='py-2'>
                    <a className="border border-gray-300 shadow-sm
                        rounded-md p-2 text-gray-700 font-normal hover:bg-gray-50 hover:text-gray-700"
                        onClick={(e) => {
                            e.stopPropagation()
                            history.push(storePath + SHOW_RETURN_ORDERS.replace(":id", order.return.id))
                        }}>
                        {i18n.t("orders.list.view_return")}
                    </a>
                </div>
            </div>
            : order.status === 'canceled' ?
            <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                <FormattedDate date={order.updated_at} shortDate={true}/>
            </div>
            : order.status === 'shipped' && order?.shipping_date && (order?.shipping_method?.shipping_type === "pickup" || !order?.shipped_shipment_date) ?
            <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                <FormattedDate date={order.shipping_date} shortDate={true}/>
            </div>
            : order.status === 'shipped' && order?.shipping_method?.shipping_type !== "pickup" && order?.shipped_shipment_date ?
            <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                <FormattedDate date={order.shipped_shipment_date} shortDate={true}/>
            </div>
            :
            <></>}
        </div>
        )
    }

    const buildIncidentsOverview = (order) => {
        return ( <>
        {order.shipping_incidents && order.shipping_incidents.length > 0 && 
            <div className="mt-3 relative max-w-14 whitespace-normal italic">
                <div className='shadow-sm rounded-md py-1.5 px-2 bg-gray-50
                    font-normal text-xs w-max'>
                    <span className='font-semibold'>{i18n.t(`orders.shipping_incident.category.${order.shipping_incidents[0].category}`)}</span>
                    <br></br>
                    <span>{i18n.t("orders.shipping_incident.reported_to_carrier")}</span>
                    <div> 
                        {order.shipping_incidents[0].status === "PENDING" &&
                            <span className="">
                                {i18n.t(`orders.shipping_incident.status.${order.shipping_incidents[0].status}`)}
                            </span>
                        }
                        {order.shipping_incidents[0].status === "IN_REVIEW" &&
                            <span className="text-pink-600">
                                {i18n.t(`orders.shipping_incident.status.${order.shipping_incidents[0].status}`)}
                            </span>
                        }
                        {order.shipping_incidents[0].status === "RESOLVED" ?
                        <div className="inline mr-0.5 text-green-600">
                            {i18n.t(`orders.shipping_incident.resolution.${order.shipping_incidents[0].resolution}`)}
                        </div>
                        : <>
                            {/* <div className="inline ml-1 mr-0.5">{i18n.t('orders.list.since')}</div>
                            <FormattedDate date={order.shipping_incidents[0].updated_at} shortDate={true}/> */}
                            </>
                        }
                    </div>
                </div>
            </div>
        }
        </>)
    }

    const buildExtendedZoneLabel = (order) => {
        return (<>
            {order.extended_zone &&
            <div>
                <span className="mt-1 whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block">{i18n.t("orders.list.extended_zone")}
                    <Tooltip placement="bottom" title={t("orders.list.extended_zone_tooltip")}>
                    <InformationCircleIcon className="inline w-4 h-4 mb-0.5 ml-1" />
                </Tooltip>
                </span>
            </div>
            }
        </>)
    }

    const buildTrackingStatus = (order) => {
        let estimated_time_arrival = new Date(order.estimated_time_arrival)
        let validate_estimated_time_arrival =  estimated_time_arrival > new Date(2020, 1, 1) 
        const wh_time_zone = user.current_store?.warehouses[0]?.time_zone
        const userInSameTimezone = wh_time_zone == Intl.DateTimeFormat().resolvedOptions().timeZone

        const noTrackingStatusHighlighted = <>
            {order.requires_external_label ?
                <div className="whitespace-normal pr-2">
                    {i18n.t('orders.list.external_label_desc')}
                </div>
            : ["canceled", "empty", "interrupted", "entirely_dropshipping", "returning", "returned","reentered", "return_arrived"].includes(order.status) ? 
                <div className="font-normal text-base text-gray-700 italic"> {i18n.t("orders.list.no_pieces")} </div>
              : <div>
                    <div className="font-normal text-base italic text-gray-500"> {i18n.t("orders.list.no_information")}</div>
                    {validate_estimated_time_arrival && 
                    <div className={`flex items-center space-x-1 mt-2 text-sm ${order.is_delayed ? 'text-red-400' : ''}`}>
                        <span className='mr-1'>{i18n.t("orders.list.eta")}:</span>
                        <FormattedRelativeTime forceDays={true} date={order.estimated_time_arrival} time_zone={user.current_store?.warehouses[0]?.time_zone}/>
                        {!userInSameTimezone && <>
                            <Tooltip placement="bottom" title={t("orders.list.time_zone_info", 
                                {time_zone: user.current_store?.warehouses[0]?.country == "MX" ? "CDMX"
                                : user.current_store?.warehouses[0]?.country == "BR" ? "SP" : wh_time_zone})}>
                                <InformationCircleIcon className="inline w-4 h-4 ml-1" />
                            </Tooltip>
                        </>}
                    </div>}
                </div>
            }
            {buildExtendedZoneLabel(order)}
            {order.virtual_status === "waiting_pick_up" && buildIncidentsOverview(order)}
            </>

        if (!order.shipping_status || Object.values(STILL_NOT_SHIPPED_SHIPMENT_STATUSES).includes(order.shipping_status) ) return noTrackingStatusHighlighted

        const description = order.last_tracking_event?.metadata?.description
        const tracking_description = order.shipping_status == "shipment_returned" ? 'in_return' : TRACKING_DESCRIPTIONS.has(description) ? description : null

        if (order.status === "shipped" && (TRACKING_STATUSES.has(order.shipping_status) || order.is_delayed)) {
            const isEtaRescheduled = !order.original_eta || order.estimated_time_arrival === order.original_eta ? false : true

            return (
            <>
                <StatusHighlighted
                    className=""
                    status={order.shipping_status}
                    statuses={TRACKING_STATUSES_DEFINITIONS}
                    onClick={()=>handlerOnClickStatus(order)}
                    description={tracking_description}
                />
                {validate_estimated_time_arrival &&  !['shipment_delivered', 'shipment_canceled'].includes(order.shipping_status) && !TRACKING_INCIDENT_FINAL_DESCRIPTIONS.has(tracking_description) && 
                <>
                <div className={`flex items-center  mt-2 text-sm ${order.is_delayed ? 'text-red-400' : ''}`}>
                    <span className='mr-1'>
                        {isEtaRescheduled ? i18n.t("orders.list.eta_rescheduled") : i18n.t("orders.list.eta")}:
                    </span>
                    <FormattedRelativeTime forceDays={true} date={order.estimated_time_arrival} time_zone={user.current_store?.warehouses[0]?.time_zone}/>
                    {!userInSameTimezone && <>
                        <Tooltip placement="bottom" title={t("orders.list.time_zone_info",
                            {time_zone: user.current_store?.warehouses[0]?.country == "MX" ? "CDMX"
                            : user.current_store?.warehouses[0]?.country == "BR" ? "SP" : wh_time_zone})}>
                            <InformationCircleIcon className="inline w-4 h-4 ml-1" />
                        </Tooltip>
                    </>}
                </div>
                { isEtaRescheduled && (
                    <div className='flex items-center  mt-2 text-sm text-red-400'>
                        <span className='mr-1'>{i18n.t("orders.list.original_eta")}:</span>
                        <FormattedRelativeTime forceDays={true} date={order.original_eta} time_zone={user.current_store?.warehouses[0]?.time_zone}/>
                    </div>
                )}
                </>}
                { order.last_tracking_event?.reference_timestamp &&
                <div className="mt-2 ml-0.5">
                    {(['shipment_delivered','shipment_with_incident','shipment_canceled']).includes(order.shipping_status) ?
                    <div className='text-gray-400 text-sm'>
                        <div className="inline mr-1">{i18n.t('orders.list.at')}</div>
                        <FormattedDate date={order.last_tracking_event.reference_timestamp} shortDate={true}/>
                    </div>
                    : null
                    }
                </div>
                }
                {buildExtendedZoneLabel(order)}
                {buildIncidentsOverview(order)}
            </>
            )
        }

        return noTrackingStatusHighlighted
    }
    const memoizedTableData = useMemo(() => {
        return data?.orders.map((order)=>{
            return {
                object: order,
                order_number: buildOrderNumberColumn(order),
                status: buildStatusHighlighted(order),
                shipping_status: buildTrackingStatus(order),
                shipping_method: getShippingMethodColumn(order),
                created_at: <FormattedDate date={order.channel_created_at} shortDate={true} />,
                full_name: <>
                            <div className="whitespace-normal font-normal text-base text-gray-700">
                                {order.full_name}
                           </div>
                           {order.is_first_time_buyer &&
                            <span className="whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block mt-1.5">{i18n.t("orders.showContainer.is_first_time_buyer")}</span>
                           }
                           </>,
                shipping_email: order.shipping_email,
                // shipping_address_1: order.shipping_address_1?.length > 50 ? order.shipping_address_1.substring(0, 50) + "..." : order.shipping_address_1,
                actions: (
                    <ActionMenu className="float-right" items={getMenuOptions(order)}/>
                )
            }
        })
    }, [data?.orders])

    const getColumns = () => {
        if(shippingMethodsDataGroup) {
            let newColumns = [...table_columns]
            let index = newColumns.findIndex(column => column.accessor === "shipping_method")
            if(index === -1) {
                newColumns.splice(1,0,{
                    Header: 'orders.list.shipping_method',
                    accessor: 'shipping_method',
                    searchAs: 'carrier_name'
                })
            }
            return newColumns
        }
        return []
    }

    const memoizedColumns = useMemo(() => getColumns(), [shippingMethodsDataGroup])

    const handleActionClick = (action) => {
        let newOrderActionModalProps = {
          open: true,
          action: action,
          orders: cloneDeep(selectedOrders),
        }
        setOrderActionModalProps(newOrderActionModalProps)
    }

    const onActionCancel = result => {
        setOrderActionModalProps({ ...orderActionModalProps, open: false })
      }
      const onActionSuccess = result => {
        setOrderActionModalProps({ ...orderActionModalProps, open: false })
        handleDeselectOrders()
        refetch()
      }
    
      const handleDeselectOrders = () => {
        setDeselectOrders(true)
      }
    
    const exportNotificationBody = (
        <>
            <div className="w-0 flex-1 flex justify-between">
                {exportFileUrl &&
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                }
                <p className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">
                    {!exportFileUrl ? <> {i18n.t('export_notification.generating')} </> 
                    : <> {i18n.t('export_notification.generated')} </>}
                </p>
                {!exportFileUrl &&
                    <p className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{exportProgress}%</p>
                }
                {exportFileUrl &&
                    <button
                        type="button"
                        className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleExportDownload}
                    >
                        Descargar
                    </button>
                }
            </div>
        </>
    )

    const getFlatFileFields = () => {

        let fields = [
            { label: i18n.t("orders.fileFields.order_number"), key: "order_number", 
                validators: [
                    { validate: "required" },
                    {
                        validate: "regex_excludes",
                        regex: "\\s",
                        error:i18n.t("orders.addContainer.no_whitespace")
                    },
                ],
                alternates: ["ID", "Número de Orden", "Número de Pedido", "Pedido"]
            },
            { label:i18n.t("orders.fileFields.sku"), key: "product_sku", 
                validators: [
                    { validate: "required" }
                ],
                alternates: ["produto", "producto"]
            },
            { label: i18n.t("orders.fileFields.quantity"), key: "product_quantity", 
                validators: [
                    {
                        validate: "regex_matches",
                        regex: "^[0-9]+$",
                        error:i18n.t("orders.addContainer.only_numbers")
                    },
                    {
                        validate: "required",
                    }
                ],
                alternates: ["qty"]
            },
        ]

        if (user.current_store.warehouses[0].country === "BR") {
            fields.push(                   
                        {   label: i18n.t("orders.fileFields.unit_price"), key: "price_per_item", 
                            validators: [
                                {
                                    validate: "regex_matches",
                                    regex: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                    error:
                                    i18n.t("orders.addContainer.only_numbers")
                                },
                                {
                                    validate: "required",
                                }
                            ],
                            alternates: ["unit_price"]
                        },
                        {   label: i18n.t("orders.fileFields.discount_per_item"), key: "discount_per_item", 
                            validators: [
                                {
                                    validate: "regex_matches",
                                    regex: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                    error:
                                    i18n.t("orders.addContainer.only_numbers")
                                }
                            ],
                            alternates: ["desconto"]
                        }, 
                        {   label: i18n.t("orders.fileFields.shipping_price"), key: "shipping_price", 
                            validators: [
                                {
                                    validate: "regex_matches",
                                    regex: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                    error:
                                    i18n.t("orders.addContainer.only_numbers")
                                },
                                {
                                    validate: "required",
                                }
                            ],
                            alternates: ["preco_frete"]
                        },
                        {   label: i18n.t("orders.fileFields.name"), key: "shipping_name", 
                            validators: [
                                { validate: "required" }
                            ],
                            alternates: ["Nombre", "nome", "Razao Social"]    
                        },
                        {   label:i18n.t("orders.fileFields.last_name"), key: "shipping_lastname", 
                            alternates: ["Apellido", "Last Name"]
                        },
                        {   label: i18n.t("orders.fileFields.cpf_cnpj"), key: "billing_tax_id", 
                            validators: [
                                { validate: "required" }
                            ],
                            alternates: ["cpf", "cnpj"]    
                        },
                        {   label:  i18n.t("orders.fileFields.state_estadual"), key: "billing_state_registration", 
                            alternates: ["inscrição"]
                        },
                        {   label: "Companhia", key: "billing_company"
                        },
                        {   label:  i18n.t("orders.fileFields.shipping_email"), key: "shipping_email", 
                            validators: [{ validate: "required" }],
                            alternates: ["Email", "E-mail"]    
                        },
                        {   label: i18n.t("orders.fileFields.shipping_phone"), key: "shipping_phone",
                            alternates: ["Teléfono"]    
                        },
                        {   label: i18n.t("orders.fileFields.shipping_address1"), key: "shipping_address_1", 
                            validators: [{ validate: "required" }],
                            alternates: ["Dirección 1", "logradouro", "rua"]    
                        },
                        {   label: i18n.t("orders.fileFields.shipping_address2"), key: "shipping_address_2", 
                            alternates: ["complemento"]
                        },
                        {   label:  i18n.t("orders.fileFields.shipping_number"), key: "shipping_number", 
                            validators: [ { validate: "required" }],
                            alternates: ["numero"]    
                            },
                        {   label:  i18n.t("orders.fileFields.shipping_neighborhood"), key: "shipping_neighborhood", 
                            validators: [{ validate: "required" }],
                            alternates: ["bairro"]    
                        },
                        {   label:  i18n.t("orders.fileFields.shipping_city"), key: "shipping_city", 
                            validators: [{ validate: "required" }],
                            alternates: ["ciudad", "cidade"]    
                        },
                        {   label: i18n.t("orders.fileFields.shipping_state"), key: "shipping_state", 
                            validators: [{ validate: "required" }],
                            alternates: ["Estado"]    
                        },
                        {   label: i18n.t("orders.fileFields.shipping_zip_code"), key: "shipping_zipcode", 
                            validators: [{ validate: "required" }],
                            alternates: ["Código Postal", "Codigo Postal", "cep"]    
                        },
                        {   label: i18n.t("orders.fileFields.shipping_country"), key: "shipping_country", 
                            validators: [{ validate: "required" }],
                            alternates: ["País, Pais"]    
                        },
    
                        {   label: i18n.t("orders.fileFields.shipping_configuration"), key: "shipping_configuration_name",
                            alternates: ["Carrier", "Envio", "frete"]    
                        },
                        {   label: i18n.t("orders.fileFields.order_type"), key: "order_type", 
                            validators: [{ validate: "required" }],
                            type: "select",
                            options: [
                                { value: "SALE", label: "SALE", alternates: ["venda"] },
                                { value: "DONATION", label: "DONATION", alternates: ["donaçao"] }
                            ],
                            alternates: ["doação ou venda", "canal"]    
                        },
                        {   label: i18n.t("orders.fileFields.billing_tax_regime"), key: "billing_tax_regime", 
                            validators: [{ validate: "required" }],
                            type: "select",
                            options: [
                                { value: "PHYSICAL", label: "PHYSICAL", alternates: ["fisico"] },
                                { value: "LEGAL", label: "LEGAL", alternates: [] }
                            ],
                            alternates: ["Regime", "regime fiscal (fisico/legal)"]    
                        }
                       
                
            )
        } else {
            fields.push(
                    {   label:i18n.t("orders.fileFields.name"), key: "shipping_name", 
                        validators: [{ validate: "required" }],
                        alternates: ["Nombre"]    
                    },
                    {   label:i18n.t("orders.fileFields.last_name"), key: "shipping_lastname", 
                        alternates: ["Apellido", "Last Name"]
                    },
                    {   label: i18n.t("orders.fileFields.shipping_email"), key: "shipping_email", 
                        validators: [{ validate: "required" }],
                        alternates: ["Email", "E-mail"]    },
                    {   label:i18n.t("orders.fileFields.shipping_phone"), key: "shipping_phone", 
                        validators: [{ validate: "required"}],
                        alternates: ["Teléfono"]    },
                    {   label: i18n.t("orders.fileFields.shipping_address1"), key: "shipping_address_1", 
                        validators: [{ validate: "required" }],
                        alternates: ["Dirección 1"]    
                    },
                    {   label: i18n.t("orders.fileFields.shipping_address2"), key: "shipping_address_2"},
                    {   label: "City", key: "shipping_city", 
                        validators: [{ validate: "required" }],
                        alternates: ["Dirección 2"]    
                    },
                    {   label:i18n.t("orders.fileFields.shipping_state"), key: "shipping_state", 
                        validators: [{ validate: "required" }],
                        alternates: ["Estado"]    },
                    {   label: i18n.t("orders.fileFields.shipping_zip_code"), key: "shipping_zipcode", 
                        validators: [{ validate: "required" }],
                        alternates: ["Código Postal", "Codigo Postal"]    },
                    {   label:i18n.t("orders.fileFields.shipping_country"), key: "shipping_country", 
                        validators: [{ validate: "required" }],
                        alternates: ["País, Pais"]    
                    },
                    {   label: i18n.t("orders.fileFields.shipping_configuration"), key: "shipping_configuration_name", 
                        validators: [{ validate: "required" }],
                        alternates: ["Carrier, Envio"]    
                    },
                    {
                        label: i18n.t("orders.fileFields.is_cod"), key: "is_cod",
                        alternates: ["Is COD", 'cod'],
                        type: "checkbox",
                    },
                    {
                        label: i18n.t("orders.fileFields.total_price"), key: "total_price",
                        alternates: ["Total price","Precio total"]
                    },
                    // {
                    //     label: i18n.t("orders.fileFields.coin"), key: "currency",
                    //     alternates: ["Currency","Currency"],
                    //     type: "select",
                    //     options: [
                    //         { value: "MXN", label: "MXN", alternates: [] },
                    //         { value: "COP", label: "COP", alternates: [] },
                    //         { value: "BRL", label: "BRL", alternates: [] }
                    //     ]
                    // }
            )
        }

    return fields
        
    }

    const CreateOrdersMenu = (
        <Menu>
            <Menu.Item>
                <a className="cursor-pointer" onClick={() => {
                        dispatch(resetData())
                        window.analytics.track('Orders - Starts Creating', {type: "REGULAR ORDER MANUALLY"})
                        history.push(storePath + ADD_ORDER)
                    }     
                }>
                    {i18n.t("orders.list.create_order")}
                </a>
            </Menu.Item>
            {/*<Menu.Item>
                <FlatfileButton
                                    licenseKey="8096b433-6f21-43de-ab84-c07472944581"
                                    customer={{ userId: "12345" }}
                                    settings={{
                                    type: "Import Orders in Bulk",
                                    fields: getFlatFileFields(),
                                    theme: {
                                        buttons: {
                                            primary: primaryButton
                                        }
                                        // other keys below
                                    },
                                    managed: true,
                                    autoDetectHeaders: true,
                                    title: i18n.t("orders.addContainer.imports_products"),
                                    devMode: false
                                    }}
                                    onData={async (results) => {
                                    // do something with the results
                                    // use data instead of alldata for valid data
                                        let batch = await enqueueOrdersBulkCreation(user.current_store?.id, results.validData)
                                        console.log("Orders Bulk Creation...", results.validData)
                                        console.log("batch", batch)
                                        history.push(storePath + CREATE_ORDER_BULK_TRACKER.replace(':id', batch.batch_id))
                                        return i18n.t("orders.addContainer.imported");
                                    }}
                                    render={(importer, launch) => {
                                        //return <button className="bg-white mr-5 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={launch}>{i18n.t("orders.addContainer.import")}</button>;
                                        return <a className="cursor-pointer" onClick={() => {
                                            window.analytics.track('Orders - Starts Creating', {type: "REGULAR ORDER BULK"})
                                            launch()
                                        }}>{i18n.t("orders.addContainer.import_orders")}</a>
                                    }}
                                    fieldHooks={{
                                        product_sku: async (values) => {
                                            console.log("Initial Values", values)
                                            let storeSkus = await fetchStoreSkus(user.current_store?.id, values.map(a => a[0]))
                        
                                            let rows_to_fix = []
                                            values.forEach(([item, index]) => {
    
                                                if (!storeSkus.hasOwnProperty(item)) {
                                                    rows_to_fix.push([{
                                                        info: [
                                                          {
                                                            message: i18n.t("replenishments.addContainer.skus_doesnt_exist"),
                                                            level: "error"
                                                          }
                                                        ]
                                                      },
                                                      index]
                                                    )
                                                }
                                                
                                            })
                                            return rows_to_fix
                                        },
                                        shipping_configuration_name: async (values) => {
                                            let storeShippingGroups = await fetchStoreShippingGroups(user.current_store?.id)
                                            console.log("Initial Values", values)
                                            console.log("Initial Shipping Groups", storeShippingGroups)

                                            let rows_to_fix = []
                                            values.forEach(([item, index]) => {
                                                const searchObject = storeShippingGroups.find((shippingGroup) => shippingGroup.name.toLowerCase() === item.toLowerCase())
                                                if (!searchObject) {
                                                    rows_to_fix.push([{
                                                        info: [
                                                          {
                                                            message: "Este grupo de envío no existe en tu tienda.",
                                                            level: "error"
                                                          }
                                                        ]
                                                      },
                                                      index]
                                                    )
                                                }
                                                
                                            })
                                            return rows_to_fix
                                            
                                        }
                                    }}
                                >
                                Import Contacts
                </FlatfileButton>
            </Menu.Item>*/}
            <Menu.Item>
            <button disabled={!isImplerInitiated} onClick={handleImport}>
                {i18n.t("orders.addContainer.import_orders")}
            </button>
            </Menu.Item>
            <Menu.Item>
                <a className="cursor-pointer" onClick={() => {
                        dispatch(resetData())
                        window.analytics.track('Orders - Starts Creating', {type: "REMOVAL ORDER MANUALLY"})
                        history.push(storePath + ADD_ORDER_REMOVE_STOCK)
                    }     
                }>
                    {i18n.t("orders.list.create_remove_stock")}
                </a>
            </Menu.Item>

            

        </Menu>
    );

    const openSpecialExportDialog = (exportOption) => {
        setProductsDetail(exportOption)
        setExportDialogOpen(true)
    }

    return (
        <>
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({...dialog, open: value}) }
                title={dialog.title}
                description={dialog.description}
                confirmLabel="Si"
                onConfirm={onConfirmDialogHandler}
                loading={dialog.loading}
            />

            <ConfirmDialog
                open={exportDialogOpen}
                setOpen={ value => setExportDialogOpen(value) }
                title={i18n.t("orders.list.export_title")}
                description={MemoizedExportDescription}
                confirmLabel={i18n.t("orders.list.export_dialog.confirm")}
                cancelLabel={i18n.t("orders.list.export_dialog.cancel")}
                errorMessage={exportError}
                onConfirm={onConfirmExport}
                loading={enqueueingExport}
            />

            <Notification
                show={exportNotificationOpen}
                setShow={setExportNotificationOpen}
                customBody={exportNotificationBody}
                ephemeral={false}
            />

            <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold"><span className="text-gray-400">{ user.current_store ? user.current_store.name : i18n.t("orders.list.all_stores") } &rarr; </span>{i18n.t("orders.list.orders")}</div>
                    <div className="items-center flex">
                    <ButtonLoader className="whitespace-nowrap" type={"secondary"} onClick={() => history.push(storePath + CLAIMS)}>
                        {i18n.t("orders.list.disputes")}
                    </ButtonLoader>
                    
                    <Dropdown disabled={false} overlay={CreateOrdersMenu} placement="bottomLeft" className="relative inline-block text-left">
                        <button className="ml-2 mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm whitespace-nowrap force-5px-border-radius">
                            {i18n.t("orders.list.create_orders")}
                            <ChevronDownIcon
                            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                            />
                        </button>
                    </Dropdown>
                   
                    
                    </div>
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
                //bottomMenu={<Button>Crear Orden</Button>}
            >
                
                {(!isPreviousData && isLoading) || (!isPreviousDataShippingMethodsGroup && isLoadingShippingMethodsGroup) 
                 || (!isPreviousDataChannelIntegrations && isLoadingChannelIntegrations)? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : isErrorShippingMethodsGroup ? (
                    <>Error: {errorShippingMethodsGroup.message}</>
                ):(
                    <>
                        <div className="flex flex-col">
                            <nav className='flex relative rounded-lg border bg-white py-2 mb-2 text-gray-700'>
                                <div className="flex flex-col lg:flex-row justify-start">
                                    <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2">
                                        {i18n.t("orders.list.requiring_resolution")}
                                    </div>
                                    <div className="max-w-min flex flex-col md:static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50"
                                            onClick={() => callFilterShortcut([{key: "status", value: ['backorder']}], 'Orders - Quick Filter - Out of Stock')}>
                                                <StatusHighlighted status="backorder" statuses={ORDER_STATUSES}/> <span className='ml-4 text-base font-bold'>{dataSummary?.analytics_data?.backorder_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.list.orders")}</span>
                                        </button>
                                    </div>
                                    <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50"
                                            onClick={() => callFilterShortcut([{key: "status", value: ['error']}], 'Orders - Quick Filter - Missing info')}>
                                                <StatusHighlighted status="error" statuses={ORDER_STATUSES}/> <span className='ml-4 text-base font-bold'>{dataSummary?.analytics_data?.missing_info_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.list.orders")}</span>
                                        </button> 
                                    </div>
                                </div>
                                <div className="flex flex-col lg:flex-row justify-start border-l-2 lg:border-0 ml-5 lg:ml-0">
                                    <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2 border-0 lg:border-l-2 pl-0 lg:pl-5">
                                        {i18n.t("orders.list.requiring_monitoring")}
                                    </div>
                                    <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50"
                                            onClick={() => callFilterShortcut([{key: "is_delayed", value: true}], 'Orders - Quick Filter - Delayed')}>
                                                <StatusHighlighted status="delayed" statuses={[{
                                                    status: "delayed",
                                                    label: "statuses.order_statuses.delayed",
                                                    classes: "bg-yellow-50 text-yellow-500"
                                                }]}/>
                                                <span className='ml-4 text-base font-bold'>{dataSummary?.analytics_data?.delayed_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.list.orders")}</span>
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <NewTable
                            deselectOrders = {deselectOrders}
                            setDeselectOrders = {setDeselectOrders}
                            data = {memoizedTableData}
                            columns={memoizedColumns}
                            showLoader={isFetching && isPreviousData}
                            showPaginationOnFooter
                            isFetching={isFetching}
                            emptyTableText= {i18n.t("orders.list.no_orders_to_show")}
                            footerLabel={i18n.t("orders.list.footer_label")}
                            paginationMeta={data?.meta}
                            onPaginationChange= { (page) => { 
                                setSearchParams((prev) => ({...prev, page: page})) 
                            }}
                            onPageSizeChange = { (page) => {
                                setSearchParams((prev) => ({...prev, per_page: page.id, page: 1}))
                                dispatch(setOrdersListPageSize(page.id))
                            }}
                            onSortChange={onSortChange}
                            onFilterChanged={onFilterChanged}
                            handleResetFilters={handleResetFilters}
                            filtersData={filtersData}
                            onClickFilterDropdown={(filter_key) => {
                                window.analytics.track('Orders - Adds Filter From List', {filter_key: filter_key});
                            }}
                            openExportDialog={(exportOption) => openSpecialExportDialog(exportOption)}
                            hasSpecialExport={true}
                            rowProps={row => ({
                                onClick: (e) => handlerOnClickStatus(row.values.status?.props.order)
                            })}
                            onSelectionChange={(rows) => {
                                setSelectedOrders(rows)
                            }}
                            selectable={true}
                        />
                        <OrderActionModal
                            orders={orderActionModalProps.orders}
                            onSuccess={onActionSuccess}
                            onCancel={onActionCancel}
                            open={orderActionModalProps.open}
                            action={orderActionModalProps.action}
                            actionParameters={orderActionModalProps.actionParameters}
                        />
                        <OrderMultiselectionBar
                            selectedOrders={selectedOrders} 
                            onDeselectOrders={handleDeselectOrders}
                            handleActionClick={handleActionClick}
                        />
                    </>
                    )
                }
            </PageView>
            <Switch>
                <Route exact path={storePath + ADD_ORDER}>
                    <SlidePanel title= {i18n.t("orders.list.create_order")} referrer={storePath + ORDERS} ref={AddOrderSlidePanelRef}>
                        <AddOrderContainer onCancel={() => AddOrderSlidePanelRef.current.closePanel()}/>
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + EDIT_ORDER}>
                    <SlidePanel title= {i18n.t("orders.list.edit_order")} referrer={storePath + ORDERS} ref={EditOrderSlidePanelRef}>
                        <AddOrderContainer edit={true} onCancel={() => EditOrderSlidePanelRef.current.closePanel()}/>
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + DUPLICATE_ORDER}>
                    <SlidePanel title= {i18n.t("orders.list.duplicate_order")}  referrer={storePath + ORDERS} ref={DuplicateOrderSlidePanelRef}>
                        <DuplicateOrderContainer duplicate={true} onCancel={() => DuplicateOrderSlidePanelRef.current.closePanel()}/>
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + SHOW_ORDERS} >
                    <SlidePanel title= {i18n.t("orders.title")}  referrer={storePath + ORDERS} dismissable={!showOrderDetailBusy} onClose={() => refetchAll()} divide_y={false}>
                        <ShowOrderContainer onBusyChange={(v) => setShowOrderDetailBusy(v)}/>
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + CREATE_CLAIM}>
                    <SlidePanel title= {i18n.t("orders.list.create_claim")}  referrer={storePath + ORDERS} ref={CreateClaimSlidePanelRef}>
                        <CreateClaimContainer onCancel={() => CreateClaimSlidePanelRef.current.closePanel()} onSuccess={(order_id, category) => handleClaimCreationSuccess(order_id, category)}/>
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + CLAIM_CREATED}>
                    {createdClaimMotive &&
                        <SlidePanel title={i18n.t("orders.list.claim_created")} referrer={storePath + ORDERS} ref={ClaimCreatedSlidePanelRef}>
                            <ClaimCreatedContainer motive={createdClaimMotive} onCancel={() => ClaimCreatedSlidePanelRef.current.closePanel()}/>
                        </SlidePanel>
                    }
                </Route>
                <Route exact path={storePath + CREATE_RETURN}>
                    <SlidePanel
                        title= {i18n.t("orders.list.create_return")} 
                        referrer={storePath + ORDERS}
                        ref={createReturnSlidePanelRef}
                    >
                        <CreateReturnContainer
                            onSuccess={(returnId = null) => {
                                if (returnId) {
                                    history.push(storePath + SHOW_RETURN.replace(':id', returnId))
                                }
                                else {
                                    history.push(storePath + RETURNS)
                                }
                            }}
                            onCancel={() => createReturnSlidePanelRef.current.closePanel()}
                        />
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + ADD_ORDER_REMOVE_STOCK}>
                    <SlidePanel
                        title= {i18n.t("orders.list.create_remove_stock")} 
                        referrer={storePath + ORDERS}
                        ref={CreateRemoveStockSlidePanelRef}
                    >
                        <AddRemoveStockContainer
                          
                            onCancel={() => CreateRemoveStockSlidePanelRef.current.closePanel()}
                            onSuccess={(order_number) => handleAddRemoveStockSuccess(order_number)}
                        />
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + EDIT_ORDER_REMOVE_STOCK}>
                    <SlidePanel title= {i18n.t("orders.list.edit_order_remove_stock")} referrer={storePath + ORDERS} ref={EditRemoveStockSlidePanelRef}>
                        <AddRemoveStockContainer edit={true} onCancel={() => EditRemoveStockSlidePanelRef.current.closePanel()}/>
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + REMOVE_STOCK_CREATED}>
                    
                    <SlidePanel title={i18n.t("orders.list.create_remove_stock")} referrer={storePath + ORDERS} ref={RemoveStockCreatedSlidePanelRef}>
                        <RemoveStockCreatedContainer order_number={orderNumber} onCancel={() => RemoveStockCreatedSlidePanelRef.current.closePanel()}/>
                    </SlidePanel>
                    
                </Route>
                <Route exact path={STORE_PATH + SHOW_RETURN_ORDERS}>
                    <SlidePanel title={i18n.t("returns.container.return_detail")} referrer={storePath + ORDERS}>
                        <ShowReturnContainer />
                    </SlidePanel>
                </Route>

            </Switch>
        </>
    )
}